:root {
  --base-color: 211;

  --black: hsla(var(--base-color), 0%, 0%, 1);
  --white: hsla(var(--base-color), 0%, 100%, 1);
  --theme-black: hsla(var(--base-color), 2%, 30%, 1);
  --theme-white: hsla(var(--base-color), 100%, 99%, 1);
  --light-color: hsla(var(--base-color), 4%, 67%, 1);
  --light-grey-bg: hsla(var(--base-color), 0%, 96%, 1);

  --theme-color: hsla(var(--base-color), 100%, 50%, 1);
  --theme-color-dark: hsla(var(--base-color), 100%, 42%, 1);
  --light-theme-bg: hsla(var(--base-color), 100%, 99%, 1);
  --light-theme-bg1: hsla(var(--base-color), 65%, 92%, 1);

  --light-font: BalooTamma2-SemiBold;
  --regular-font: BalooTamma2-Regular;
  --medium-font: BalooTamma2-Medium;
}

@font-face {
  font-family: BalooTamma2-SemiBold;
  src: url("../fonts/BalooTamma2-SemiBold.ttf");
}
@font-face {
  font-family: BalooTamma2-Regular;
  src: url("../fonts/BalooTamma2-Regular.ttf");
}
@font-face {
  font-family: BalooTamma2-Medium;
  src: url("../fonts/BalooTamma2-Medium.ttf");
}

html {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: var(--light-font);
  width: 100%;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  background-color: var(--white);
  color: var(--theme-black);
  zoom: 0.75;
}
.modal-backdrop {
  zoom: 1.5;
}
.nopadding {
  padding: 0px !important;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
}
p {
  font-size: 16px;
  line-height: 26px;
  color: var(--light-color);
}
.ft-14 {
  font-size: 15px !important;
}
.ft-18 {
  font-size: 20px !important;
}
.theme-bg {
  background-color: var(--theme-color) !important;
}
.theme-color {
  color: var(--theme-color) !important;
}
.dark-color,
.dark-color p {
  color: var(--theme-black) !important;
}
.light-color {
  color: var(--light-color) !important;
}
.white-color,
.white-color p {
  color: var(--theme-white) !important;
}
.regular {
  font-family: var(--regular-font) !important;
}
.medium {
  font-family: var(--medium-font) !important;
}
.ft-18-title {
  font-size: 20px;
  color: var(--theme-black);
  font-family: var(--regular-font);
}
.light-theme-bg {
  background-color: var(--light-theme-bg1) !important;
}

/*login register page*/

.login-box-height {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box {
  padding: 20px;
  display: block;
  max-width: 520px;
  margin: auto;
  height: auto;
  max-height: 100vh;
  overflow: auto;
}
.login-btn-sec {
  padding: 0px 50px;
}
.login-box::-webkit-scrollbar {
  display: none;
}

.theme-btn {
  display: inline-block;
  color: var(--white) !important;
  padding: 9px 25px;
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  box-shadow: 0px 3px 6px #00000008 !important;
  border-radius: 12px;
  outline: 0 !important;
  transition: all ease 0.5s;
}

.loading {
  padding: 25px 25px;
}

.theme-btn:hover {
  background-color: var(--theme-color-dark);
}
.secondary-btn {
  display: inline-block;
  color: black !important;
  padding: 9px 25px;
  background-color: var(--light-theme-bg1);
  border: 1px solid var(--light-theme-bg1);
  box-shadow: 0px 3px 6px #00000008 !important;
  border-radius: 12px;
  outline: 0 !important;
  transition: all ease 0.5s;
}
.secondary-btn:hover {
  background-color: var(--light-theme-bg);
}
.danger-btn {
  display: inline-block;
  color: var(--white) !important;
  padding: 9px 25px;
  background-color: #bb2124;
  border: 1px solid #bb2124;
  box-shadow: 0px 3px 6px #00000008 !important;
  border-radius: 12px;
  outline: 0 !important;
  transition: all ease 0.5s;
}
.danger-btn:hover {
  background-color: #a00c0f;
}
.btn-icon {
  margin-right: 10px;
  margin-top: -3px;
}
.white-border-btn {
  color: var(--theme-black) !important;
  background-color: var(--white);
  border-color: #f2f2f2;
}
.white-border-btn:hover {
  background-color: var(--light-grey-bg);
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  color: #6b6c6d;
  font-family: var(--regular-font);
  margin-bottom: 8px;
}
.form-control {
  color: #6b6c6d;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 9px 20px;
  color: var(--theme-black);
}
.form-select {
  background-color: #ffffff !important;
}
.form-control::placeholder {
  color: #babdc1;
}
.form-control:focus {
  box-shadow: none;
  outline: 0;
  border-color: var(--theme-color);
}
.form-control:disabled,
.form-control:read-only {
  background-color: var(--light-theme-bg1);
  opacity: 1;
}
.form-control:focus {
  box-shadow: none;
  outline: 0;
  border-color: var(--theme-color);
}
.form-control:disabled,
.form-control:read-only {
  background-color: var(--light-theme-bg1);
  opacity: 1;
}
.hover-underline:hover {
  text-decoration: underline;
}
.custom.checkbox > label,
.custom.radio > label {
  position: relative;
  cursor: pointer;
  padding-left: 32px;
}
.custom input[type="checkbox"],
.custom input[type="radio"] {
  position: relative;
  margin-left: -26px;
  margin-right: 12px;
  cursor: pointer;
}
.custom input[type="checkbox"]:after,
.custom input[type="radio"]:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -4px;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
}
.custom input[type="checkbox"]:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 1px;
  top: 4px;
  z-index: 1;
  width: 9px;
  height: 5px;
  border: 1px solid var(--theme-color);
  border-top-style: none;
  border-right-style: none;
}
.custom input[type="checkbox"]:checked:after,
.custom input[type="radio"]:checked:after {
  border-color: var(--theme-color);
}
.custom input[type="checkbox"]:checked:before {
  transform: rotate(-45deg) scale(1, 1);
}
.custom input[type="checkbox"]:after {
  border-radius: 3px;
}
.custom input[type="radio"] {
  top: -2px;
}
.custom input[type="radio"]:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 3px;
  left: 0px;
  z-index: 1;
  width: 10px;
  height: 10px;
  background: var(--theme-color);
  border-radius: 50%;
}
.custom input[type="radio"]:checked:before {
  transform: scale(1, 1);
}
.custom input[type="radio"]:after {
  border-radius: 50%;
}
.input-icon-div {
  position: relative;
}
.input-icon-div .form-control {
  padding-left: 40px;
}
.input-icon-div .input-icon {
  position: absolute;
  left: 12px;
  bottom: 11px;
  color: var(--light-color);
}
.login-box form {
  width: 100%;
}
.sign-form {
  display: none;
}
.hero-bg-img {
  width: 100%;
  height: 133.5vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 10px;
  display: flex;
}
.carousel-indicators {
  margin-bottom: 2rem;
}
.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  border: none;
}
.hero-content {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding-bottom: 3rem;
}
.hero-img {
  max-height: 65vh;
  width: auto;
}
.mini-title {
  font-size: 25px;
  line-height: 35px;
  font-family: var(--medium-font);
}

.login-btn {
  background-color: #007aff !important;
  border-color: #007aff !important;
}
.login-btn:hover {
  background-color: #0068d6 !important;
}
.login-blue-color {
  color: #007aff !important;
}
.login-box .custom input[type="checkbox"]:before {
  border-color: #007aff;
}
.login-box .custom input[type="checkbox"]:checked:after,
.login-box .custom input[type="radio"]:checked:after {
  border-color: #007aff;
}
.login-box .custom input[type="radio"]:before {
  background: #007aff;
}

/*admin theme*/

/*sidebar-div*/
.sidebar-div-box {
  width: 280px;
  height: 150vh;
  position: fixed;
  left: 0px;
  top: 0px;
  background: #fafafa;
  box-shadow: 0px 0px 20px #0000000d;
  overflow: hidden;
  z-index: 999;
}
.sidebar-header {
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}
.sidebar-header a {
  cursor: pointer;
}
.sidebar-div {
  padding: 20px 25px;
  height: calc(135vh - 110px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-div::-webkit-scrollbar {
  width: 8px;
}
.sidebar-div::-webkit-scrollbar-track {
  background: #f3f4f6;
}
.sidebar-div::-webkit-scrollbar-thumb {
  background: #d1d5db;
}

.sidebar-list {
  background-color: transparent;
  border-radius: 15px;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #212529;
  font-family: var(--regular-font);
  transition: all ease 0.5s;
  cursor: pointer;
}
.sidebar-list.active {
  background-color: #ffffff;
  box-shadow: 0px 3px 20px #00000003;
  color: var(--theme-black);
}
.sidebar-list::before {
  content: "";
  opacity: 0;
  visibility: hidden;
  width: 7px;
  height: 36px;
  display: inline-block;
  background: var(--theme-color);
  box-shadow: 0px 3px 20px #00000005;
  border-radius: 0px 9px 9px 0px;
  transition: all ease 0.5s;
  position: absolute;
  left: 0px;
}
.sidebar-list.active::before {
  visibility: visible;
  opacity: 1;
}
.sidebar-list:hover {
  color: var(--theme-black);
}
.sidebar-list-icon {
  display: inline-block;
  min-width: 1.25rem;
  width: 1.25rem;
  height: auto;
  margin-right: 0.75rem;
  margin-top: -5px;
}
.sidebar-list-icon svg {
  width: 18px;
  height: auto;
}
.upgrade-sidebar-box {
  background-color: #ffffff;
  box-shadow: 20px 20px 20px #00000008;
  border-radius: 15px;
  padding: 8px 15px 15px;
  margin-bottom: 50px;
}
.upgrade-profile {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  object-fit: cover;
}
.disable-layer {
  position: relative;
  overflow: hidden;
}
.disable-layer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.75;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100;
}
.sidebar-menu-close {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--theme-black);
}

/*main-div*/
.main-div {
  width: 100%;
  min-height: 100vh;
  padding-left: 280px;
  background-color: #f8f8f8;
  height: 135vh;
}

/*header div*/
.header-div {
  padding: 0px 50px;
  width: 100%;
  background-color: #f8f8f8;
  position: sticky;
  top: 0px;
  z-index: 99;
}
.header-div-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 1px solid #e3e3e3;
}
.search-input .form-control {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 3px 20px #00000003;
  border-radius: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 50px;
}
.search-input.input-icon-div .input-icon {
  left: 18px;
  bottom: 13px;
}
.header-btn {
  color: var(--theme-black) !important;
  background-color: var(--white);
  border: none;
  min-width: 160px;
  text-align: left;
  padding: 6px 35px 6px 10px;
  white-space: nowrap;
  box-shadow: none !important;
  border-radius: 30px;
  outline: 0 !important;
  transition: all ease 0.5s;
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  font-weight: bold;
  font-family: var(--regular-font);
}
.rounded-btn {
  border-radius: 30px;
}
.round-color-icon {
  height: 35px;
  min-width: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-grey-bg);
}
.header-minute-text {
  display: flex;
  align-items: center;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #e3e3e3;
  white-space: nowrap;
  font-family: var(--medium-font);
  cursor: pointer;
}
.dropdown-toggle {
  padding: 0px;
  box-shadow: none !important;
  outline: 0 !important;
}
.no-toggle-icon::after {
  display: none;
}
.dropdown-menu {
  border: none;
  border-radius: 6px;
  padding: 0.25rem 0rem;
  overflow: hidden;
  box-shadow: 0px 0px 10px #00000029;
}
.dropdown-item {
  color: var(--theme-black);
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  color: var(--theme-black);
  background-color: var(--light-grey-bg);
}
.selected-speaker {
  color: var(--theme-black);
  background-color: var(--light-grey-bg);
}
@media all {
  .header-div .dropdown-menu {
    top: 180% !important;
    right: 0px !important;
    left: auto !important;
    transform: none !important;
    transition: all ease 0.3s;
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  .header-div .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    top: 100% !important;
  }
}
.brand-drop-logo {
  height: 25px;
  width: auto;
}
.res-header-dash-sticky {
  padding: 5px 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e3e3e3;
  position: sticky;
  top: 63.83px;
  z-index: 98;
}
.search-modal .btn-close {
  position: relative !important;
  top: auto;
  right: auto;
}
.search-modal .modal-dialog {
  width: 100%;
  margin: 0px;
  max-width: 100%;
}
.search-modal .modal-content {
  border-radius: 0px;
  border: none;
}
.search-modal .modal-body {
  padding: 10px;
}
.search-modal .modal-body .search-input {
  width: 100%;
}

/*content-area*/
.content-area {
  position: relative;
  padding: 30px 50px;
}
.card-title {
  font-size: 20px;
  color: var(--theme-black);
  font-family: var(--medium-font);
  margin-bottom: 25px;
}
.card-margin {
  margin-bottom: 30px;
}
.card {
  display: block;
  border: none;
  background-color: #ffffff;
  box-shadow: 20px 20px 20px #1a242e08;
  border-radius: 36px;
  overflow: hidden;
}
.card-hover {
  cursor: pointer;
  transition: all ease 0.5s;
}
.card-hover:hover {
  background-color: var(--light-theme-bg1);
  /*    box-shadow: 20px 20px 20px #1a242e14;*/
}
.card-100 {
  height: 100%;
}
.card-padding {
  padding: 20px;
}
.img-icon-list {
  height: 85px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-icon-list span {
  margin: 10px 15px;
}
.img-icon-list img {
  max-height: 65px;
  width: auto;
}
.team-profiles-div {
  display: inline-flex;
}
.team-profiles-img {
  height: 60px;
  min-width: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid var(--theme-color);
  margin-left: -30px;
  object-fit: cover;
  background-color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-profiles-div img:first-child {
  margin-left: 0px;
}
.pointer {
  cursor: pointer;
}
.table {
  border-color: #e3e3e3 !important;
  color: var(--light-color);
  vertical-align: middle;
}
.table th {
  font-family: var(--regular-font);
  font-weight: initial;
  color: var(--theme-black);
}
.table th,
.table td {
  padding: 5px 10px;
}
.table tbody tr td:first-child,
.table tbody tr th:first-child {
  width: 40px;
}
.table tbody tr:last-child td,
.table tbody tr:last-child th {
  border-bottom: none;
}
.action-icon-list {
  display: inline-flex;
}
.icons-hover {
  min-width: 40px;
  width: 40px;
  height: 30px;
  padding: 0px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease;
}
.icons-hover:hover,
.icons-hover:focus {
  border-radius: 50%;
  background-color: var(--light-grey-bg);
}

.tooltip-inner {
  background-color: var(--light-grey-bg);
  color: var(--theme-black);
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] > .tooltip-arrow::before {
  border-bottom-color: var(--light-grey-bg);
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] > .tooltip-arrow::before {
  border-top-color: var(--light-grey-bg);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] > .tooltip-arrow::before {
  border-left-color: var(--light-grey-bg);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] > .tooltip-arrow::before {
  border-right-color: var(--light-grey-bg);
}
@media (min-width: 767px) {
  .modal-dialog {
    max-width: 650px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.no-file-card {
  background-color: var(--light-theme-bg);
  width: 50%;
}
.modal-content {
  background: var(--light-theme-bg);
  border-radius: 28px;
  overflow: hidden;
  border: none;
  z-index: 99;
}
.modal-content-right-radius {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
.modal-body,
.modal-body-padding {
  padding: 35px 50px;
}
.btn-close {
  opacity: 0.5;
  position: absolute;
  right: 20px;
  top: 20px;
  box-shadow: none !important;
  outline: 0 !important;
  z-index: 99;
}
.round-input {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 3px 20px #00000003 !important;
  border-radius: 30px;
}
.border-input {
  border: 1px solid #e6e6e6;
  box-shadow: none !important;
}
.img-list img {
  margin-right: 15px;
  margin-bottom: 15px;
}
.medium-title {
  font-size: 30px;
  line-height: 40px;
  font-family: var(--medium-font);
}
.filename-container {
  display: flex; /* Uses flexbox layout */
  align-items: center; /* Vertically centers children if they're of different heights */
}

.filename {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px; /* Adjust this if necessary */
  cursor: pointer;
}

.small-title {
  font-size: 22px;
  line-height: 33px;
  color: var(--theme-black);
  font-family: var(--regular-font);
  display: flex;
  align-items: center; /* Vertically center content */
}
.small-title span,
.small-title a {
  white-space: nowrap; /* Prevents the content from wrapping to the next line */
}

.small-title .filename {
  flex: 1; /* This will make the filename take the maximum available space */
  overflow: hidden; /* Hide excess content */
  text-overflow: ellipsis; /* Add ... for overflow content */
  margin: 0 10px; /* Add some space around filename */
  width: initial;
  max-width: 250px;
}

@media screen and (max-width: 540px) {
  .billing-h3 {
    justify-content: center !important;
  }
}

.accordion-item {
  border: 1px solid #e3e3e3;
  position: relative;
  padding-left: 25px;
}
.accordion-button {
  font-family: var(--medium-font);
  color: var(--theme-black) !important;
  padding: 25px 0px;
  line-height: 24px;
  background-color: #fff !important;
  box-shadow: none !important;
  border: none;
  align-items: flex-start;
}
.accordion-flush .accordion-collapse .accordion-body {
  color: var(--theme-black);
  padding: 0px;
  padding-bottom: 15px;
}
.collapse-icon {
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 23px;
  color: var(--theme-color);
  z-index: 9;
  font-size: 20px;
  line-height: 24px;
}
.accordion-button::after {
  content: "\f107";
  font: normal normal normal 24px/1 FontAwesome;
  background-image: none !important;
  color: var(--theme-color);
  height: auto;
  width: auto;
}
.card-padding-small {
  padding: 15px;
}
.border-dashed-box {
  border: 1px dashed #d9d9d9;
  border-radius: 22px;
  padding: 15px;
  height: 100%;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec-pad-top {
  padding-top: 40px;
}
.record-time-div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 24px;
  font-family: var(--medium-font);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 3px solid #f9fcff;
}
.transparent-white-btn {
  color: var(--white) !important;
  background-color: var(--theme-color);
  border: 2px solid var(--white);
  box-shadow: 0px 3px 20px #00000003;
  border-radius: 15px;
}
.transparent-white-btn:hover {
  background-color: var(--white);
  color: var(--theme-color) !important;
}
.progress {
  width: 100%;
  height: 18px;
  border-radius: 50px;
  background-color: var(--light-theme-bg1);
}
.progress-bar {
  background-color: var(--theme-color);
}
.nav-pills {
  border-bottom: 1px solid #e3e3e3;
}
.icon-tab.nav-pills .nav-link {
  height: 55px;
  min-width: 100px;
}
.nav-pills .nav-link {
  background: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 3px solid transparent;
  padding: 0px 15px;
  color: var(--theme-black);
  opacity: 0.5;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--theme-black);
  background-color: transparent;
  border-bottom-color: var(--theme-color);
  opacity: 1;
}
.dark-p p {
  color: var(--theme-black);
  font-family: var(--regular-font);
}
ol,
ul {
  margin-bottom: 0px;
  padding-left: 1rem;
}
.drag-upload-div {
  background-color: #ffffff;
  border: 1px dashed var(--theme-color);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  min-height: 240px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
.underline {
  text-decoration: underline !important;
}
.img-shadow-box {
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
}
.logout-icon-div {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: inline-flex;
  align-content: center;
  justify-content: center;
}
.logout-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #ff3938;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  transition: all ease 0.5s;
}
.logout-box-card:hover .logout-icon {
  height: 70px;
  width: 70px;
}
.account-profile {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  object-fit: cover;
  margin-bottom: 20px;
}
hr {
  background-color: #e3e3e3;
  opacity: 1;
}
.profile-list {
  display: flex;
  margin-bottom: 15px;
}
.profile-list p {
  margin-bottom: 0px;
}
.profile-list-title {
  min-width: 100px;
  padding-right: 10px;
}
.profile-list-desc-div {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 100px);
}
.profile-list-desc {
  color: var(--theme-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.desc-copy-icon {
  min-width: 16px;
}
.profile-card-box {
  position: relative;
  cursor: pointer;
}
.profile-card-hover-div {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #007aff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
}
.profile-card-box:hover .profile-card-hover-div {
  opacity: 1;
}
.green-bg {
  background-color: #00d13f !important;
}
.purple-bg {
  background-color: #6a6cff !important;
}
.yellow-bg {
  background-color: #dc9301 !important;
}
.red-bg {
  background-color: #ff3938 !important;
  border-color: #ff3938 !important;
}
.meetingtor-bg {
  background-color: #059426 !important;
  border-color: #059426 !important;
}
.text-danger {
  color: #ff3938 !important;
}
.edit-profile-div {
  display: inline-block;
  position: relative;
}
.edit-profile-icon {
  position: absolute;
  bottom: 4px;
  right: -2px;
  cursor: pointer;
  height: 35px;
  min-width: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-white);
  background-color: var(--theme-color);
}
.round-theme-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--light-theme-bg1);
  border: 1px solid var(--theme-color);
}
.red-border-btn {
  color: #ff3938 !important;
  background-color: var(--white);
  border-color: #ff3938;
}
.red-border-btn:hover {
  color: var(--white) !important;
  background-color: #ff3938;
}
.min-btn-width {
  min-width: 140px;
}
.table-page-icon {
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--light-theme-bg1);
  color: var(--theme-color);
}
.table-search {
  width: 600px;
}
.pagination-sm .page-link {
  padding: 0px;
  font-size: 0.875rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px !important;
  background-color: #f3f3f3 !important;
  border: none !important;
  margin-left: 5px !important;
  font-family: var(--regular-font);
  color: var(--light-color) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.page-item.active .page-link {
  background-color: var(--theme-color) !important;
  color: var(--theme-white) !important;
}
.small-table-btn {
  display: inline-block;
  color: var(--white) !important;
  padding: 8px 20px;
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  box-shadow: 0px 3px 6px #00000003 !important;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  outline: 0 !important;
}
.green-btn-bg {
  background-color: #00d13f !important;
  border-color: #00d13f !important;
}
.purple-btn-bg {
  background-color: #6a6cff !important;
  border-color: #6a6cff !important;
}
.red-btn-bg {
  background-color: #ff3938 !important;
  border-color: #ff3938 !important;
}
.blue-logo-text {
  color: #007aff;
}
.pink-logo-text {
  color: #a60298;
}
.yellow-logo-text {
  color: #ff9819;
}
.text-switch .btn-toggle {
  margin: 0 5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 14px;
  width: 51px;
  border-radius: 50px;
  color: #000;
  background: #efefef;
  border: none;
  font-weight: 600;
}
.text-switch .btn-toggle:focus,
.text-switch .btn-toggle.focus,
.text-switch .btn-toggle:focus.active,
.text-switch .btn-toggle.focus.active {
  outline: none;
  box-shadow: none;
}
.text-switch .btn-toggle:before,
.text-switch .btn-toggle:after {
  width: 5rem;
  font-family: var(--regular-font);
  color: var(--theme-black);
  text-transform: initial;
  letter-spacing: 1px;
  position: absolute;
  bottom: -5px;
  transition: opacity 0.5s;
}
.text-switch .btn-toggle:before {
  content: var(--before-content, "Monthly");
  text-align: right;
  padding-right: 10px;
  left: -5rem;
  width: fit-content;
}

.text-switch .btn-toggle:after {
  content: var(--after-content, "Annual");
  text-align: left;
  padding-left: 10px;
  right: -5rem;
  opacity: 0.5;
}
.text-switch .btn-toggle.active:before {
  opacity: 0.5;
}
.text-switch .btn-toggle.active:after {
  opacity: 1;
}
.text-switch .btn-toggle .handle {
  position: absolute;
  top: -9px;
  left: 0px;
  width: 32px;
  height: 32px;
  border-radius: 1.125rem;
  background: var(--theme-color);
  transition: left 0.5s;
}
.text-switch .btn-toggle.active .handle {
  left: 19px;
  transition: left 0.5s;
}
.offer-div {
  background-color: #00d13f;
  border-radius: 7px;
  padding: 4px 7px;
  color: var(--theme-white);
  font-family: var(--regular-font);
  font-size: 15px;
  line-height: 18px;
}
.package-price {
  font-size: 65px;
  line-height: 70px;
  font-family: var(--medium-font);
}
.package-input {
  width: 100%;
  max-width: 300px;
  margin: auto;
}
.counter-input {
  width: 100%;
  margin: auto;
  max-width: 210px;
  position: relative;
}
.counter-input .form-control {
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
}
.count {
  font-size: 20px;
  font-family: var(--regular-font);
  min-width: 44px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
}
.count.c-plus {
  right: 0px;
}
.count.c-minus {
  left: 0px;
}
.feature-box {
  text-align: center;
}
.feature-box p {
  font-family: var(--regular-font);
  color: var(--theme-black);
  margin-bottom: 0px;
}
.feature-text-pad {
  padding: 8px 30px;
}
.feature-text-div {
  padding: 0px 10px;
  text-align: left;
}
.f-border {
  border-right: 1px solid #e0e8f1;
}
.f-tag {
  display: inline-block;
  background-color: var(--light-theme-bg1);
  color: var(--theme-color) !important;
  padding: 5px 20px;
  border-radius: 10px;
  min-width: 110px;
  text-align: center;
}
.light-red-bg {
  background-color: #ffebeb;
  color: #ff3938 !important;
}
.moretext {
  display: none;
}
.choose-list {
  padding: 8px 15px;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
}
.choose-list.active {
  background-color: var(--light-theme-bg1);
  color: var(--theme-color);
}
.choose-list span svg {
  margin-top: -3px;
}
.vertical-flex-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.successfully-shared {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  color: var(--theme-color);
  border-radius: 8px;
  background-color: var(--light-theme-bg1);
  padding: 0.75em 1em;
  width: 100%;
  max-width: 350px;
  font-family: var(--regular-font);
  font-size: 17px;
}
.successfully-shared.show {
  display: block;
}
.res-action-dropdown {
  display: none;
}
.res-action-dropdown.dropdown .dropdown-toggle {
  color: var(--theme-black);
}
.res-action-dropdown.dropdown .dropdown-toggle::before {
  display: none;
}
.res-action-dropdown.dropdown .dropdown-menu {
  box-shadow: 0px 0px 10px #00000029;
}
.action-drop-icon {
  min-width: 20px;
  width: 20px;
  height: auto;
  margin-right: 12px;
  text-align: center;
}

.file-all-icons {
  text-align: end;
}

.res-action-dropdown .dropdown-item {
  font-family: var(--regular-font);
  padding: 0.2rem 1rem;
  font-size: 15px;
}
.input-group .btn {
  z-index: 0;
}
.selectBox {
  position: relative;
  cursor: pointer;
}
.selectBox__value {
  background-color: #fff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: left;
}
.selectBox:after {
  position: absolute;
  right: 12px;
  bottom: 0px;
  color: var(--theme-black);
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.2s ease-in-out;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.001' height='7.165' viewBox='0 0 14.001 8.165'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23212121;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M13.861,60.224l-.7-.7a.441.441,0,0,0-.645,0L7,65.036,1.487,59.522a.441.441,0,0,0-.645,0l-.7.7a.441.441,0,0,0,0,.645l6.537,6.538a.441.441,0,0,0,.645,0l6.538-6.538a.442.442,0,0,0,0-.645Z' transform='translate(0 -59.382)'/%3E%3C/svg%3E");
}
.selectBox .dropdown-menu {
  transition: all 0.5s ease-in-out;
  display: block;
  top: 100%;
  width: 100%;
  z-index: 10;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 0px;
}
.selectBox.show:after {
  transform: translateY(-50%) rotate(180deg);
}
.selectBox.show .dropdown-menu {
  transition: all 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
.min-100vh-relative-div {
  min-height: calc(100vh - 119px);
  position: relative;
  padding-bottom: 170px;
}

.audio-play-div {
  position: fixed;
  z-index: 99;
  bottom: 0px;
  left: 330px;
  right: 0px;
  width: calc(100% - 380px);
  background-color: var(--light-theme-bg);
  box-shadow: -20px -20px 20px #04274d0d;
  border-radius: 36px 36px 0px 0px;
  padding: 35px 140px 12px;
}

.audio-play-div-sharing {
  position: fixed;
  z-index: 99;
  bottom: 0px;
  background-color: var(--light-theme-bg);
  box-shadow: -20px -20px 20px #04274d0d;
  border-radius: 36px 36px 0px 0px;
  width: 100%;
  padding: 20px 0px 10px 0px;
}

.audio-details-div {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio-play-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 25px;
}
.audio-play-icons img,
.audio-play-icons svg {
  max-height: 23px;
  width: auto;
}
.play-theme-round {
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  margin: 20px 25px;
}
.play-speed {
  color: var(--theme-color);
  height: 51px;
  width: 51px;
  border-radius: 50%;
  border: 2px solid var(--theme-color);
  font-family: var(--medium-font);
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.play-time {
  color: var(--theme-color);
  font-size: 15px;
  border-radius: 50px;
  border: 2px solid var(--theme-color);
  font-family: var(--medium-font);
  border-radius: 27px;
  padding: 8px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.slider-theme-icon {
  min-width: 70px;
  width: 70px;
  height: 70px;
  padding: 10px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #d4e8ff;
  color: #088aff;
}
.slider-theme-icon-small {
  min-width: 50px;
  width: 50px;
  height: 50px;
}
.slider-p {
  font-family: var(--regular-font);
  color: var(--theme-black);
  margin-bottom: 0px;
}
.slider-hover-card {
  position: relative;
  cursor: pointer;
  margin: 0px 12px;
  box-shadow: 8px 8px 8px #1a242e08;
}
.slider-hover-box {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #088aff;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
  padding: 20px;
}
.slider-hover-box p {
  color: #fff;
  margin-bottom: 0px;
}
.slider-hover-card:hover .slider-hover-box {
  opacity: 1;
}
.yellow-theme-slider .slider-theme-icon {
  background-color: #ffecd4;
  color: #ff9819;
}
.yellow-theme-slider .slider-hover-box {
  background-color: #ff9819;
}
.pink-theme-slider .slider-theme-icon {
  background-color: #ffdffc;
  color: #a60298;
}
.pink-theme-slider .slider-hover-box {
  background-color: #a60298;
}
.file-slider .carousel-indicators {
  position: relative;
  margin-top: 10px;
}
.file-slider .carousel-indicators [data-bs-target] {
  background-color: var(--theme-color);
  opacity: 0.2;
}
.file-slider .carousel-indicators .active {
  opacity: 1;
}
.water-slider .range-slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.water-slider .range-slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}
.water-slider .range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.water-slider .range-slider input[type="range"]:focus {
  outline: none;
}
.water-slider .range-slider input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.water-slider .range-slider__range {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 5px 0px;
  z-index: 9;
  cursor: pointer;
}
.water-slider .range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--theme-color);
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.water-slider .range-slider__range::-webkit-slider-thumb:hover {
  background: var(--theme-color);
}
.water-slider .range-slider__range:active::-webkit-slider-thumb {
  background: var(--theme-color);
}
.water-slider .range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: var(--theme-color);
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}
.water-slider .range-slider__range::-moz-range-thumb:hover {
  background: var(--theme-color);
}
.water-slider .range-slider__range:active::-moz-range-thumb {
  background: var(--theme-color);
}
.range-slider__value {
  display: none;
}
.nav-pills.btn-pills {
  border-bottom: none;
}
.nav-pills.btn-pills .nav-link {
  background: var(--light-theme-bg);
  border: 1px solid var(--theme-color);
  border-radius: 50px;
  border-bottom: 1px solid;
  padding: 6px 25px;
  color: var(--theme-color);
  opacity: 1;
  margin-right: 10px;
  font-family: var(--regular-font);
}
.nav-pills.btn-pills .nav-link.active,
.nav-pills.btn-pills .show > .nav-link {
  color: var(--theme-white);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  opacity: 1;
}
.btn-tab-icon img {
  height: 24px;
  width: auto;
}
.btn-active-icon {
  display: none;
}
.nav-pills.btn-pills .nav-link.active .btn-tab-icon img.btn-reg-icon {
  display: none;
}
.nav-pills.btn-pills .nav-link.active .btn-tab-icon img.btn-active-icon {
  display: inline-block;
}
.file-action-icon-flex {
  display: inline-flex;
  flex-wrap: wrap;
}
.file-action-list {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
.file-action-list img,
.file-action-list svg {
  width: 100%;
  max-width: 20px;
  height: auto;
}
.water-slider.text-slider .range-slider__range {
  height: 12px;
}
.water-slider.text-slider .range-slider__range::-webkit-slider-thumb {
  width: 30px;
  height: 30px;
}
.water-slider.text-slider .range-slider__range::-moz-range-thumb {
  width: 30px;
  height: 30px;
}
.selectBox.disable .selectBox__value {
  background-color: var(--light-theme-bg1) !important;
}
.selectBox.disable.show .dropdown-menu {
  visibility: hidden !important;
  opacity: 0 !important;
}
.selectBox.disable.show:after {
  transform: translateY(-50%) rotate(0deg) !important;
}
.info-icon {
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-theme-bg1);
  color: var(--theme-color);
  margin-right: 8px;
  margin-top: 2px;
}
.credit-round-btn {
  background-color: var(--light-theme-bg);
  border: 1px solid var(--theme-color);
  line-height: 20px !important;
}
.credit-round-btn .ft-18 {
  line-height: 20px !important;
}
.credit-round-btn .round-color-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.files-tab-div-height {
  width: 100%;
  /*height: calc(100vh - 380px);*/
  height: calc(102vh) !important;
  overflow-y: scroll;
}

@media (max-aspect-ratio: 1/1) {
  .files-tab-div-height {
    height: calc(120vh) !important;
  }
}

.files-tab-div-height::-webkit-scrollbar,
.ai-chat-div-height::-webkit-scrollbar,
.transkript-list-res-height::-webkit-scrollbar, .list-container::-webkit-scrollbar{
  width: 10px;
}
.files-tab-div-height::-webkit-scrollbar-track,
.ai-chat-div-height::-webkit-scrollbar-track,
.transkript-list-res-height::-webkit-scrollbar-track, .list-container::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 50px;
}
.files-tab-div-height::-webkit-scrollbar-thumb,
.ai-chat-div-height::-webkit-scrollbar-thumb,
.transkript-list-res-height::-webkit-scrollbar-thumb, .list-container::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 50px;
}
.files-tab-div-height .nav-pills.btn-pills {
  background-color: #fff;
  position: sticky;
  top: 0px;
  z-index: 9;
}
.ai-chat-div-height {
  height: calc(100vh - 70px);
  overflow-y: auto;
}
.ai-chat-div {
  border-right: 1px solid #e3e3e3;
}
.ai-chat-msg-box {
  display: flex;
  padding-right: 30px;
}
.ai-chat-msg-content {
  height: 100%;
  overflow-y: auto;
  padding-right: 30px;
  margin-bottom: 15px;
}
.ai-chat-msg-box .theme-btn img {
  min-width: 20px;
  width: 20px;
}
.ai-chat-msg-list {
  display: flex;
  margin-top: 10px;
}
.ai-chat-msg-list p {
  margin-bottom: 10px;
}
.transkript-list {
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 2px 15px;
  transition: all ease.5s;
}
.transkript-list:hover,
.transkript-list.active {
  background-color: #e4e4e4;
}
.transcript-dropdown {
  min-width: 130px;
  width: 130px;
  margin-right: 10px;
}
.transcript-dropdown .dropdown-toggle {
  min-width: 130px;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  border-radius: 11px;
  color: var(--theme-color);
  padding: 6px 15px;
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
}

.transcript-dropdown .dropdown-menu {
  min-width: 250px;
}
.transcript-time {
  min-width: 110px;
  padding: 0px 15px;
  color: var(--light-color);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
}
.transcript-time-icon svg {
  margin-top: -2px;
  margin-right: 6px;
}
.transcript-content {
  color: var(--theme-black);
  font-family: var(--regular-font);
  width: 80%;
}

.transcript-content:focus {
  outline: none;
}
.shortcodes-p p {
  margin-bottom: 8px;
}

.filename::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit */
}
.status {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.spinner {
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.processing {
  color: #999; /* Light color for processing */
}

.failed {
  color: #ff4d4d; /* Red color for failed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.chat-list-box {
  font-size: 15px;
  line-height: 20px;
  background-color: #f4f4f5;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}
.chat-list-box p {
  color: #000;
}
.semi-bold {
  font-weight: 600;
}
.loading-threedots {
  font-size: 30px;
}

.loading-threedots span {
  opacity: 0;
  animation: blink 1.4s infinite;
}

.loading-threedots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-threedots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
.files-tab-div-height::-webkit-scrollbar {
  display: block;
  overflow: auto;
}
.dropdown-menu::-webkit-scrollbar {
  display: block;
  overflow: auto;
  width: 10px;
  background-color: #ffff;
}
.dropdown-menu::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 50px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 50px;
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.highlighted {
  background-color: var(--light-theme-bg1);
}

.payment-bold {
  font-weight: 600;
  color: var(--base-color);
}

.time-speaker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-view {
  display: none;
}
.burger-mobile-view {
  display: none;
}
.burger-web-view {
  display: block;
}
.aichat-enablecard {
  width: 50%;
}
.recorder-span {
  width: 52px;
  height: 48px;
}
.recorder-span2 {
  width: 46px;
  height: 38px;
}
.recorder-img {
  width: 26px;
  height: 24px;
}

.subtype-span {
  font-size: small;
}
.payment-row {
  cursor: pointer;
}
.payment-card {
  cursor: pointer;
  transition: all 0.3s; /* Smooth transition for the effects */
}

.payment-card:hover {
  /* Example: Slight scale for a pop effect */
  transform: scale(1.03);

  /* Example: Add a shadow for depth */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/*.scale75 {
    transform: scale(0.75);
    transform-origin: top left;
  }
  */
.hover-effect {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.hover-effect:hover {
  transform: translateY(-5px);
}

.hover-span {
  visibility: hidden;
  background-color: #ffffff;
  color: var(--light-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 20;
  bottom: 105%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -30px; /* Use half of the width value to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 20px;
  white-space: nowrap; /* Prevent content from wrapping to the next line */
}

.hover-effect:hover .hover-span {
  visibility: visible;
  opacity: 1;
}

.google-div {
  max-width: 200px;
  overflow: hidden;
}

.info-terms {
  font-size: 12px;
  color: #aaaaaa;
}

.info-terms a {
  color: #0d6efd;
}
.payment-btn {
  transition: all 0.2s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.meetingtor-tab {
  border-color: #059426 !important;
}
.recorditor-tab {
  border-color: red !important;
}

.old-version-div button {
  margin-right: 5px;
}

.speaker-span {
  width: 100%;
  overflow: hidden;
}

.speaker-span-sharing {
  font-size: 0.9rem;
}

.custom-checkbox:checked .custom-label::after {
  color: var(--theme-color);
}

.css-zwbxxv-MuiPaper-root {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.css-mk03rw-MuiPaper-root {
  padding: 34px !important;
}

.top-right-modal .modal-dialog {
  position: fixed;
  top: 2rem;
  right: 2rem;
  margin: 0;
  min-width: 500px; /* Match the width of the modal to your screenshot */
}
.webcam-circle {
  display: none; /* default state, will be overridden by JavaScript when recording starts */
  position: fixed;
  bottom: 90px;
  left: 100px;
  width: 240px;
  height: 240px;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1001;
}

.webcam-video {
  height: 240px;
  width: 240px;
  object-fit: cover;
}

.video-controller {
  display: none; /* default state, will be overridden by JavaScript when recording starts */
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 50px;
  width: 300px;
  background-color: #333;
  border-radius: 30px;
  height: 60px;
  padding: 10px;
  margin: 20px;
  border: 4px solid #e0e0e0;
  z-index: 1001;
}

.progress-indicator-layer {
  position: fixed;
  top: 90px;
  right: 50px;
  margin: 20px;
  border-radius: 38px;
  border: 4px solid var(--theme-color);
  z-index: 1001;
  width: 500px;
  max-height: 500px;
  overflow-y: auto;
}

@property --progress-value {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

@keyframes html-progress {
  to {
    --progress-value: 92;
  }
}

@keyframes css-progress {
  to {
    --progress-value: 87;
  }
}

@keyframes js-progress {
  to {
    --progress-value: 73;
  }
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-header-title {
  font-weight: bold;
  font-size: large;
}

.header-controls {
  display: flex;
  gap: 10px;
}

.header-controls span {
  cursor: pointer;
  font-size: large;
}

.progress-indicator-layer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

.progress-indicator-layer::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.progress-indicator-layer::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.progress-indicator-layer::-webkit-scrollbar-thumb:hover {
  background-color: #ff0000;
}

.no-device-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #ff3938;
  border-radius: 12px;
  padding: 10px;
  font-size: 18px;
}

.no-device-text {
  margin-left: 10px;
}

.top-right-button {
  position: absolute;
  top: 12px;
  right: 12px;
  color: white; /* Download icon color */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease; /* Smooth transition for showing the icon */
}

.action-box:hover .top-right-button {
  opacity: 1;
}

.faq-modal-card {
  max-height: 90vh !important;
  overflow-y: auto;
}

.filename-hover {
  cursor: pointer;
}
.filename-span {
  display: inline-block;
  position: absolute;
  visibility: hidden;
  background-color: var(--light-theme-bg);
  color: #000;
  padding: 10px;
  padding-right: 40px;
  overflow-x: hidden !important;
  border-radius: 30%;
}
.filename-hover:hover .filename-span {
  display: block;
  visibility: visible;
}

.main-transcript-content {
  position: absolute;
  right: 0;
  width: 60%;
}

.main-seo-content {
  position: absolute;
  left: 0;
  width: 40%;
  height: 133vh;
  max-width: 40%;
  background-color: var(--light-theme-bg);
}

@media (max-width: 1200px) {
  .slogan {
    font-size: 1.2rem !important;
    line-height: 1rem !important;
  }
  .phrase {
    font-size: 1rem !important;
    line-height: 1rem !important;
  }
  .login-btn-sec {
    padding: 0px 20px !important;
    line-height: 1rem !important;
  }
  .custom-margin img {
    margin-top: 1.5rem !important; /* Example: Reducing top margin */
    margin-bottom: 1rem !important; /* Example: Reducing bottom margin */
  }
  .conversion-banner {
    bottom: 20px !important;
  }

  @media (max-width: 412px) {
    .conversion-banner {
      bottom: 20px !important;
      width: 88% !important;
      left: 6% !important;
    }
  }

  .content-text {
    font-size: 1rem !important;
  }
  .list-group {
    padding: 0rem 0.6rem !important;
    line-height: 23px !important;
  }

  .list-group-item {
    padding: 0rem 0rem !important;
  }
}

.list-group-cont {
  padding: 0rem 2rem;
}

.list-group-item {
  background-color: var(--light-theme); /* Light grey background */
  border-left: 3px solid #007bff; /* Blue left border */
  margin-bottom: 2px; /* Space between items */
  border-radius: 5px; /* Rounded corners */
  border: none;
  padding: 0rem 1rem;
}

.list-group-item:last-child {
  margin-bottom: 0; /* Remove bottom margin for the last item */
}

.conversion-banner {
  position: absolute;
  left: 17%;
  z-index: 99;
  width: 66%;
  bottom: 120px !important;
}

.conversion-banner {
  bottom: 80px !important;
}

@media (max-width: 412px) {
  .conversion-banner {
    bottom: 120px !important;
  }
}

@media (max-width: 412px) and (min-height: 800px) {
  .conversion-banner {
    bottom: 90px !important;
  }
}

@media (min-width: 413px) and (max-width: 600px) {
  .conversion-banner {
    bottom: 110px !important;
  }
}

@media (min-width: 413px) and (max-width: 600px) and (min-height: 800px) {
  .conversion-banner {
    bottom: 90px !important;
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .conversion-banner {
    bottom: 120px !important;
  }
}

@media (min-width: 500px) and (max-width: 900px) and (min-height: 800px) {
  .conversion-banner {
    bottom: 170px !important;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .conversion-banner {
    bottom: 150px !important;
  }
}

@media (min-width: 900px) and (max-width: 1023px) {
  .conversion-banner {
    bottom: 100px !important;
  }
}

@media (min-width: 900px) and (max-width: 1023px) and (min-height: 1200px) {
  .conversion-banner {
    bottom: 120px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .conversion-banner {
    bottom: 120px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) and (min-height: 1200px) {
  .conversion-banner {
    bottom: 150px !important;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .conversion-banner {
    bottom: 140px !important;
  }
}

.conversion-card {
  background-color: #0081ff;
}

.conversion-button {
  background-color: var(--light-theme-bg);
}

.conversion-button:hover {
  background-color: var(--light-theme-bg1);
}

.conversion-text {
  color: var(--light-theme-bg);
}

.conversion-button-text {
  color: #0081ff;
  font-size: 1.2rem;
}

.content-text {
  font-size: 1.2rem;
  font-family: var(--regular-font);
}

.conversion-banner .card-title {
  color: var(--theme-color);
}

.main-seo-content a {
  color: var(--theme-color);
}

.phrase {
  color: var(--theme-black);
  font-family: var(--regular-font);
  font-size: 1.5rem;
}

.locked-feature {
  opacity: 0.5;
}

.speaker-toggle::after {
  display: none;
}

.slogan {
  font-size: 2rem;
}

@media (max-width: 1023px) and (max-height: 599px), (max-aspect-ratio: 1/1) {
  .main-transcript-content {
    width: 100%;
  }
  .audio-play-div-sharing {
    width: 98% !important;
  }
  .sharing-speed {
    display: none;
  }
}

@media (min-width: 1024px) and (min-height: 600px) and (max-height: 1365px),
  (min-aspect-ratio: 1/1) {
  .audio-play-div-sharing {
    width: 60% !important;
  }
}

.order-x {
  order: 200 !important;
}

.file-row-sharing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  font-size: 1.2rem !important;
  font-weight: 600;
  padding-left: 0px !important;
}

.filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.download-icon {
  margin-left: auto; /* Ensures it stays to the right */
}

@media (max-width: 768px) {
  .file-action-list img {
    max-width: 30% !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}

.red-text {
  color: red;
}
.red-text:hover {
  color: red;
}

.main-padding-left {
  padding-left: 0px !important;
}

.main-padding-left .content-area {
  padding: 0px !important;
}

@media (max-width: 768px) {
  .editor-header .row .d-none {
    display: none; /* Ensures elements with d-none are not displayed on mobile */
  }
}
.transcription-bottom {
  height: 200px;
  max-height: 200px;
}

@media (max-width: 420px) {
  .transcription-bottom {
    height: 0 !important; /* Ensures elements with d-none are not displayed on mobile */
    max-height: 0 !important;
  }
}
.main-padding-left .transcript-content {
  font-size: 1rem !important;
  width: 100%;
}

.download-img-sharing {
  bottom: 20% !important;
  z-index: 99;
  position: sticky;
  width: 75% !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
}

@media (min-width: 560px) and (max-width: 640px) {
  .download-img-sharing {
    width: 65% !important;
  }
}

@media (min-width: 520px) and (max-width: 640px) and (min-aspect-ratio: 0.6) {
  .download-img-sharing {
    width: 50% !important;
  }
}

@media (min-width: 641px) and (max-width: 840px) {
  .download-img-sharing {
    width: 50% !important;
  }
}

.editor-bottom {
  height: 100px;
  max-height: 100px;
}

.custom-menu-style {
  position: absolute;
  left: 440px !important;
}

@media (min-width: 1920px) {
  .custom-menu-style {
    left: 600px !important;
  }
}

@media (max-width: 1920px) {
  .custom-menu-style {
    left: 530px !important;
  }
}

@media (max-width: 1880px) {
  .custom-menu-style {
    left: 515px !important;
  }
}

@media (max-width: 1840px) {
  .custom-menu-style {
    left: 500px !important;
  }
}

@media (max-width: 1800px) {
  .custom-menu-style {
    left: 480px !important;
  }
}

@media (max-width: 1760px) {
  .custom-menu-style {
    left: 460px !important;
  }
}

@media (max-width: 1700px) {
  .custom-menu-style {
    left: 450px !important;
  }
}

@media (max-width: 1630px) {
  .custom-menu-style {
    left: 440px !important;
  }
}

@media (max-width: 1560px) {
  .custom-menu-style {
    left: 440px !important;
  }
}

@media (max-width: 1500px) {
  .custom-menu-style {
    left: 420px !important;
  }
}

@media (max-width: 1440px) {
  .custom-menu-style {
    left: 400px !important;
  }
}

@media (max-width: 1400px) {
  .custom-menu-style {
    left: 390px !important;
  }
}

@media (max-width: 1360px) {
  .custom-menu-style {
    left: 370px !important;
  }
}

@media (max-width: 1280px) {
  .custom-menu-style {
    left: 360px !important;
  }
}

@media (max-width: 1220px) {
  .custom-menu-style {
    left: 340px !important;
  }
}

@media (max-width: 1160px) {
  .custom-menu-style {
    left: 300px !important;
  }
}

@media (max-width: 1076px) {
  .custom-menu-style {
    left: 270px !important;
  }
}

@media (max-width: 1026px) {
  .custom-menu-style {
    left: 250px !important;
  }
}

@media (max-width: 991px) {
  .custom-menu-style {
    left: 200px !important;
  }
}

@media (max-width: 951px) {
  .custom-menu-style {
    left: 180px !important;
  }
}

@media (max-width: 890px) {
  .custom-menu-style {
    left: 170px !important;
  }
}

@media (max-width: 840px) {
  .custom-menu-style {
    left: 150px !important;
  }
}

@media (max-width: 756px) {
  .custom-menu-style {
    left: 130px !important;
  }
}

@media (max-width: 666px) {
  .custom-menu-style {
    left: 110px !important;
  }
}

@media (max-width: 590px) {
  .custom-menu-style {
    left: 100px !important;
  }
}

@media (max-width: 530px) {
  .custom-menu-style {
    left: 90px !important;
  }
}

@media (max-width: 490px) {
  .custom-menu-style {
    left: 80px !important;
  }
}

@media (max-width: 450px) {
  .custom-menu-style {
    left: 70px !important;
  }
}

@media (max-width: 410px) {
  .custom-menu-style {
    left: 60px !important;
  }
}

@media (max-width: 360px) {
  .custom-menu-style {
    left: 50px !important;
  }
}

@media (max-width: 322px) {
  .custom-menu-style {
    left: 35px !important;
  }
}

.fileitem-linkto {
  display: block;
  width: 100%;
  min-height: 100%;
  text-align: end;
}

.css-i9gxme {
  color: transparent;
}

.no-border-bottom td {
  border-bottom: none;
}

.table-fixed-height {
  position: relative; /* Establish a positioning context */
}

.action-buttons-overlay {
  position: absolute;
  top: 50%; /* Center vertically in the table */
  left: 50%; /* Center horizontally in the table */
  transform: translate(-50%, -50%); /* Offset the transform */
  z-index: 100; /* Make sure it sits above the placeholders */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Use the full width of the container */
}

.table-placeholder-row {
  visibility: hidden; /* Make placeholders invisible */
}

.action-buttons-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex; /* Align items horizontally */
  flex-direction: row; /* Set direction to row for horizontal layout */
  align-items: center; /* Center-align items vertically */
  justify-content: center; /* Center content horizontally */
  width: auto; /* Adjust width automatically based on content size */
}

@media (max-width: 768px) {
  .no-content-message {
    text-align: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 20px; /* Adjust based on your design needs */
  }
}

.action-buttons-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .action-buttons-container {
    flex-direction: column;
    align-items: center;
  }
}

.no-file-available {
  font-size: large;
}

@media (max-width: 400px) {
  .no-file-available {
    font-size: medium;
  }
}

@media (max-width: 320px) {
  .no-file-available {
    font-size: small;
  }
}

@media (max-width: 290px) {
  .no-file-available {
    font-size: x-small;
  }
}

.action-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  overflow: hidden;
  overflow-x: hidden;
  width: fit-content;
}

@media (max-width: 768px) {
  .action-buttons-container {
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    overflow: hidden;
    overflow-x: hidden;
    width: fit-content;
  }
}

action-buttons-overlay {
  overflow: hidden !important;
}
.chat-user-list-box {
  align-items: flex-end;
  display: flex;
}
.chat-user-list-box p {
  width: fit-content;
  margin-left: auto;
  border: 1px solid var(--light-theme-bg1);
  padding: 10px;
  border-radius: 10px;
  background-color: var(--light-theme-bg1);
  color: black;
  color: var(--theme-black);
  font-family: var(--regular-font)
}
.chat-user-list-box p:hover {
  color: black;
}
.chat-assistant-list-box{
  color: var(--theme-black);
}
.chat-assistant-list-box p {
  width: fit-content;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--light-grey-bg);
  color: black;
  color: var(--theme-black);
  font-family: var(--regular-font)
}
.chat-assistant-list-box p:hover {
  color: black;
}

.chat-suggested-list-box {
  align-items: flex-end;
  display: flex;
}
.chat-suggested-list-box p {
  width: fit-content;
  margin-left: auto;
  border: 1px solid var(--light-theme-bg1);
  padding: 5px;
  border-radius: 10px;
  color: var(--theme-color);
  cursor: pointer;
}
.chat-suggested-list-box p:hover {
  color: black;
}

.editor-page {
  position: fixed;
}

.feature-review-card {
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow:none;
}

.feature-review-title {
  color: var(--theme-color);
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.visible {
  visibility: visible;
  opacity: 1;
}

/* ConversionBanner.css */

.header-conversion-banner {
  background-color: var(--theme-color); /* A bright color to attract attention */
  color: white; /* White text for contrast */
  text-align: center; /* Center the text */
  padding: 10px 0; /* Add some padding for space around the text */
  font-size: 1.2em; /* Increase font size for better readability */
  font-weight: bold; /* Make the text bold */
  position: relative; /* Make it position relative */
  width: 100%; /* Make it full width */
  cursor: pointer; /* Change cursor to pointer on hover */
}
.header-conversion-banner:hover{
  background-color: var(--theme-color-dark);
}
.header-conversion-banner p{
  color: white;
}

.header-conversion-banner svg{
  color: white;
}

.header-conversion-banner .plan-button{
  width: 200px;
  max-width: 200px;
  border: 1px solid white;
  color: var(--theme-color);
  border-radius: 15px;
  margin: 0;
  margin-left: 20px;
  background-color: white;
}
.header-conversion-banner span svg{
  width: 24px;
  height: 24px;
  fill: white;
  color:  white;
  margin-right: 2px;
}
.header-conversion-banner span svg path{
  fill:white;
  color: white;
}
.header-conversion-banner button svg{
  width: 24px;
  height: 24px;
  fill: var(--theme-color);
  color: var(--theme-color);
  margin-right: 2px;
}
.header-conversion-banner button svg path{
  fill: var(--theme-color);
  color: var(--theme-color);
}


.edu-price h5{
  text-decoration: line-through;
}
.edu-price{
  display: none;
}

.checkout-modal-view iframe{
  height: 140vh !important;
  
}

.advanced-settings-button{
  cursor: pointer;
}

.advanced-settings-button .form-control{
  text-align: center;
}

.options-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.option-item {
  padding: 8px 16px;
  border-bottom: 1px solid #ccc;
}

.option-title {
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.sub-list {
  list-style: none;
  padding-left: 20px;
  margin-top: 5px;
}

.sub-list-item {
  padding: 5px 0;
  cursor: pointer;
  user-select: none;
}

.option-title:hover, .sub-list-item:hover {
  background-color: #f0f0f0;
}

.container.mt-4 {
  margin-top: 16px;
}
.advanced-settings-container{
  min-height: 400px;
}
.advanced-settings-container .nav-link{
  cursor: pointer;
  color: var(--light-font);
}
.advanced-settings-container li>*{
  cursor: pointer;
}

.advanced-settings-container .nav-link.active{
  color: var(--theme-color);
}
.advanced-settings-container .form-check-label{
  cursor: pointer;
}

.list-group-item {
  margin: 5px;
}

.list-container{
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
.list-container::-webkit-scrollbar{
  display: block !important;
}

.tooltip-container{
  background-color: var(--light-theme-bg1) !important;
  color: black !important;
  border-radius: 20px !important;
}
.tooltip-content{
  max-width: 300px;
  text-align: left;

}

.tooltip-content span{
  font-size: 1rem;
  text-indent: 0.1rem;
}
.dictionary-tab .tooltip-content{
  max-width: 500px;
}


.user-message{
  color: var(--theme-black);
  font-family: var(--regular-font)
}