.MeetingView {
  text-align: center;
}
.video_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 360px;
  overflow: hidden !important;
}

.player__wrapper {
  position: relative;
}

.player {
  border: 2px solid #7b2cbf;
  object-fit: cover;
  padding: 0;
  margin: 0;
}

.vsc-controller {
  overflow: hidden;
  visibility: hidden;
}

@media screen and (max-width: 1024px) {
  .video-container {
    width: 80% !important;
  }
  .player__wrapper video {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 760px) {
  .video-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 640px) {
  .video-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 430px) {
  .video-outside * {
    padding: 1 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .slider__container {
    padding: 0 1px !important;
  }
  .css-1oqqzyl-MuiContainer-root {
    padding-left: 1 !important;
  }
  .Container {
    padding-right: 0 !important;
  }
  .video-full {
    padding: 0 !important;
  }
}

@media screen and (max-width: 387.5px) {
  .transkriptorTab {
    margin-top: 5px;
  }
  .nav-link {
    padding: 1rem 0rem;
  }
  .nav-item {
    padding: 0.2rem 0rem;
  }
}

.hide-controls {
  display: none;
}
  .css-1oqqzyl-MuiContainer-root{
    padding: 0 !important;
  
  }
.fullscreen-mode .css-1oqqzyl-MuiContainer-root {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 3000px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  display: flex !important;
  flex-direction: row !important;
}

.css-53tbrc-MuiSlider-root {
  padding:  2px !important;
}

.player__wrapper:hover .control_Container {
  /* display: flex !important; */
  opacity: 1 !important;
}

.player__wrapper .control_Container {
  /* display: none !important; */
  opacity: 1 !important;
}

.player__wrapper .control_Container {
  opacity: 1;
  transition: opacity 0.5s ease, z-index 0.5s ease;
  z-index: 2;
}

.player__wrapper.hide-cursor .control_Container {
  opacity: 0;
  z-index: -1;
}

.control_Container {
  transition: all 0.5s ease;
}

.hide-cursor {
  cursor: none;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: white !important;
  font-size: 5rem;
  size: 40rem;
  padding: 100px;
}

@media screen and (max-width: 760px) {
  .loading-indicator {
    font-size: 3rem;
    size: 5rem;
    color: white !important;
  }
}